<template>

<div class="events" v-if="list.length">

	<com-inner>

		<com-banner :page="page" v-if="page" :is-inline="true" />

		<div class="events-title" v-if="!window.is.mobile">Featured Events</div>

		<div class="events-item" v-for="(item, index) in list" :key="index">

			<div class="events-item-name">
				{{ item.name }}
				<small v-if="item.date">{{ item.date | formatDate('MMMM D, YYYY') }}</small>
				<small v-if="!item.date">On demand</small>
			</div>

			<router-link :to="$link(item.url)" v-if="!window.is.mobile" class="events-item-info"><app-icon s="info" /></router-link>

			<a :href="item.register" v-if="item.register" class="events-item-register">Register</a>

		</div>

	</com-inner>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	components: {
		'com-banner': () => import('../Banner')
	},

	created: function() {

		this.$api.get('events', {
			sourceID: this.block.sourceID,
			source: this.block.source
		}, 'events/featured').then(function(json) {

			this.list = json.list

		}.bind(this))

	}

}

</script>

<style scoped>

.events {
	background-image: url(~@/assets/home.events.jpg);
	background-position: 50% 50%;
	background-size: cover;
	min-height: 372px;
	padding: 100px 20px 100px 20px;
	margin-bottom: 150px;
	margin-top: 100px;
	margin: -40px calc(50% - 50vw) 100px calc(50% - 50vw);
}

.is-tablet .events {
	padding: 40px 20px;
	min-height: auto;
	margin-bottom: 20px;
}

.is-mobile .events {
	padding: 0px 0px 20px 0px;
	min-height: auto;
	margin-bottom: 20px;
	margin-top: -20px;
}

.is-desktop .events:after {
	content: '';
	position: absolute;
	left: calc(50% + 270px);
	top: 80px;
	width: 302px;
	background-image: url(~@/assets/home.events.mobile.png);
	height: 440px;
	background-size: contain;
	background-repeat: no-repeat;
}

.is-embed .events {
	margin-left: -20px;
	margin-top: 110px;
	margin-bottom: -20px;
	width: calc(100% + 40px);
}

.events-title {
	background-color: #fff;
	border-radius: 17px;
	padding: 20px 20px 40px 20px;
	position: absolute;
	font-weight: 300;
	color: #1a234c;
	font-size: 44px;
	line-height: 44px;
	text-transform: uppercase;
	width: 455px;
	top: -200px;
	left: 0px;
}

.is-tablet .events-title {
	font-size: 32px;
	width: 100%;
	line-height: 40px;
	padding: 0px;
	top: -120px;
}

.is-mobile .events-title {
	font-size: 24px;
	width: 100%;
	line-height: 24px;
	padding: 0px;
	position: relative;
	padding: 0px 10px 20px 10px;
	width: calc(100% + 20px);
	margin-left: -10px;
	border-radius: 0px;
	top: auto;
	text-align: center;
}

.events-item {
	background-color: #fff;
	border-radius: 55px;
	height: 84px;
	margin: 0px auto 20px 0px;
	padding: 0px 40px;
	max-width: 758px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.is-tablet .events-item {
	width: 100%;
	max-width: 1000px;
}

.is-mobile .events-item {
	border-radius: 25px;
	margin-bottom: 10px;
	padding: 10px;
	height: auto;
	flex-direction: column;
}

.is-mobile .events-item:nth-child(2) {
	margin-top: 20px;
}

.events-item:last-child {
	margin-bottom: 0px;
}

.events-item-name {
	color: #1a234c;
	font-size: 24px;
	line-height: 24px;
	flex-grow: 1;
}

.is-mobile .events-item-name {
	font-size: 16px;
	line-height: 22px;
	text-align: center;
}

.events-item-name small {
	display: block;
	margin-top: 5px;
	text-transform: uppercase;
	color: #1277d4;
	font-size: 16px;
	line-height: 16px;
}

.is-mobile .events-item-name small {
	font-size: 12px;
	margin-top: 0px;
	line-height: 12px;
}

.events-item-info {
	width: 36px;
	height: 36px;
	flex-shrink: 0;
	margin-left: 20px;
	border-radius: 50%;
	background-color: #1277d4;
	color: #fff;
	font-size: 16px;
	text-align: center;
	line-height: 36px;
}

.events-item-register {
	width: 142px;
	height: 36px;
	flex-shrink: 0;
	border-radius: 18px;
	margin-left: 20px;
	background-color: #c21920;
	color: #fff;
	letter-spacing: 1.2px;
	font-size: 12px;
	text-transform: uppercase;
	text-align: center;
	line-height: 36px;
}

.is-mobile .events-item-register {
	margin-left: 0px;
	margin-top: 10px;
}

.events-link {
	margin: 50px 100px 0px auto;
	color: #fff;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: bold;
	padding-right: 20px;
}

.is-desktop .events-link {
	float: right;
}

.is-tablet .events-link {
	display: flex;
	margin-top: 40px;
	justify-content: flex-end;
}

.events-link:after {
	content: '';
	position: absolute;
	width: 58px;
	height: 58px;
	border-radius: 50%;
	border: 2px solid #1D79D1;
	right: -58px;
	top: -25px;
}

.events-link >>> .icon {
	position: absolute;
	color: #1D79D1;
	right: -34px;
	font-size: 24px;
	top: -5px;
}


</style>
